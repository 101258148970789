import BaseProducts from "@/components/base/el/BaseProducts";
import {is_object, is_empty, get_att_data} from "@/components/base/utils";

const BaseSingleBlogComponent = () => import("@/components/base/blog/BaseSingleBlogComponent")
const BasePagination = () => import("@/components/base/blog/BasePagination")
// import S3Blog from "@/utils/requestS3";
import {apiBlog} from "@/apis/blogs";
import BaseCarouselComponent from "@/components/base/BaseCarouselComponent";

const component_map = {
    h1: () => import("@/components/base/el/BaseH1"),
    h2: () => import("@/components/base/el/BaseH2"),
    h3: () => import("@/components/base/el/BaseH3"),
    h4: () => import("@/components/base/el/BaseH4"),
    h5: () => import("@/components/base/el/BaseH5"),
    p: () => import("@/components/base/el/BaseP"),
    a: () => import("@/components/base/el/BaseA"),
    b: () => import("@/components/base/el/BaseB"),
    del: () => import("@/components/base/el/BaseDel"),
    img: () => import("@/components/base/el/BaseImg"),
    span: () => import("@/components/base/el/BaseSpan"),
    button: () => import("@/components/base/el/BaseButton"),
    li: () => import("@/components/base/el/BaseLi"),
    small: () => import("@/components/base/el/BaseSmall"),
    special_div: () => import("@/components/base/el/BaseDiv"),
    special_section: () => import("@/components/base/el/BaseSection"),
}

export default {
    name: "BaseMultiBlogComponent",
    mixins: [BaseProducts],
    props: {
        component_data: Object,
    },
    render(createElement) {
        if (this.is_blog_page() && !this.$store.state.fetched_blogs) {
            this.$store.state.fetched_blogs = true
            this.fetch().then((res) => {
                this.blogs = res.data.posts
                this.page_count = res.data.page_count
            })
        }
        return this.make_element_tree(createElement, this.component_data);
    },
    data() {
        return {
            is_text: false,
            intervalid: '',
            blogs: '',
            page_count: '',
            isBlogLists: true
        };
    },
    created() {
        if (process.env.NODE_ENV === "development") return
        this.fetch().then((res) => {
            this.blogs = res.data.posts
            this.page_count = res.data.page_count
        })
    },
    computed: {
        data_object() {
            let class_array = [{"deepsel-focus": this.is_editing}]
            if (this.component_data.attr && this.component_data.attr.class) {
                let class_value

                if (Array.isArray(this.component_data.attr.class)) {
                    class_value = this.component_data.attr.class.join(" ")
                } else class_value = this.component_data.attr.class

                class_array.push(class_value)
            }

            return {
                class: class_array,
                attrs: {
                    contenteditable: this.$store.state.edit_mode && !this.component_data.attr['deepsel-esc'],
                    draggable: false,
                },
                style: this.styling,
                on: {
                    focusout: this.focus_out,
                    // input: this.on_input,
                    mouseenter: this.mouse_enter,
                    mousedown: this.mouse_down,
                },
            };
        },
    },
    methods: {
        fetch() {
            if (this.$store.state.common_data.blog_from_api) {
                let url = new URL(window.parent.location.href)
                let search_params = new URLSearchParams(url.search)
                let params
                if (this.is_blog_page()) {
                    params = {
                        category: search_params.get('category'),
                        tag: search_params.get('tag'),
                        author: search_params.get('author'),
                        limit: search_params.get('limit') || 24,
                        search: search_params.get('search'),
                        page: search_params.get('page') || 1
                    }
                } else {
                    params = {
                        limit: this.component_data.attr['blog-filter-limit'] || 10,
                        tag: this.component_data.attr['blog-filter-tag'],
                        category: this.component_data.attr['blog-filter-category'],
                        highlighted: this.component_data.attr['blog-highlighted']
                    }
                }
                return apiBlog(params)
            }
        },
        change_filter(v, att) {
            let newObj = {}
            newObj[att] = v
            this.component_data.attr = {
                ...this.component_data.attr,
                ...newObj
            }
            this.fetch().then((res) => {
                this.blogs = res.data.posts
                this.page_count = res.data.page_count
            })
        },
        is_blog_page() {
            if (process.env.NODE_ENV === "development") return false
            let path = window.parent.location.pathname.split("/")
            return path[path.length - 1] === 'blogs' && is_object(this.component_data.attr) && 'is-blog-page' in this.component_data.attr
        },
        focus_out() {
        },
        make_element_tree(createElement, element_data, options = {}, context = null) {
            let data = {}
            let el, props, attrs, style, children


            if (element_data.attr && 'deepsel-multi-blog' in element_data.attr) {
                let class_array = [{"deepsel-focus": this.is_editing}]
                if (this.component_data.attr && this.component_data.attr.class) {
                    let class_value

                    if (Array.isArray(this.component_data.attr.class)) {
                        class_value = this.component_data.attr.class.join(" ")
                    } else class_value = this.component_data.attr.class

                    class_array.push(class_value)
                }
                data = {
                    class: class_array,
                    attrs: {
                        contenteditable: this.$store.state.edit_mode && !this.component_data.attr['deepsel-esc'],
                        draggable: false,
                    },
                    style: this.styling,
                    on: {
                        focusout: this.focus_out,
                        // input: this.on_input,
                        mouseenter: this.mouse_enter,
                        mousedown: this.mouse_down,
                    },
                }
            }

            [el, props] = this.get_el_props(element_data, context)
            attrs = this.get_attrs(element_data)
            children = this.get_children(element_data, createElement)
            style = this.get_style(element_data)

            if (attrs) data.attrs = {...attrs, ...data.attrs}
            if (style) data.style = {...style, ...data.style}

            if (props) data.props = props
            if (attrs) data.attrs = attrs
            if (style) data.style = style

            for (let key in options) data[key] = options[key]

            return createElement(
                el,
                data,
                children
            )
        },
        get_el_props(element_data, context) {
            let el = element_data.tag
            let props = null

            if (element_data.attr && 'is-owl-carousel' in element_data.attr) {
                el = BaseCarouselComponent
                props = {
                    component_data: element_data,
                    fetch_blog: this.fetch,
                    data_set: 'blog-list'
                }
                return [el, props]
            } else if (element_data.attr && 'deepsel-single-blog' in element_data.attr) {
                el = BaseSingleBlogComponent
                props = {
                    component_data: element_data,
                    blog_data: context.blog_data
                }
            } else if (element_data.attr && 'deepsel-pagination' in element_data.attr) {
                el = BasePagination
                props = {
                    component_data: element_data,
                    page_count: this.page_count || 0,
                }
            } else {
                if (Object.keys(component_map).includes(element_data.tag)) {
                    el = component_map[element_data.tag]
                    props = {component_data: element_data}
                }
            }

            return [el, props]
        },
        get_attrs(element_data) {
            let attrs = {}
            if (element_data.attr) {

                for (let key in element_data.attr) {
                    if (key === 'style' && is_object(element_data.attr[key])) continue
                    if (key === 'type' && element_data.tag === 'button') element_data.attr[key] = 'button'
                    let value = element_data.attr[key];
                    attrs[key] = Array.isArray(value) ? value.join(" ") : value
                }

                if (element_data.tag === "script") {
                    attrs.type = "text/javascript";
                    attrs.defer = false;
                    attrs.async = false;
                }

                let att_data = get_att_data(element_data, this.$store.state.common_data)
                if (att_data) {
                    for (let key in att_data) {
                        attrs[key] = att_data[key]
                    }
                }

                this.replace_origin_urls(attrs, element_data)

                return attrs
            }
        },
        get_style(element_data) {
            if (element_data.attr && is_object(element_data.attr.style)) {
                let origin = this.$store.state.origin;
                let style = element_data.attr.style
                if (style.backgroundImage) {
                    if (
                        !style.backgroundImage.includes("https://") &&
                        !style.backgroundImage.includes("http://")
                    ) {
                        let background_img_path = style.backgroundImage
                            .replace('url(', '')
                            .replace(')', '')
                            .replace(/'/g, '')

                        // background_img_path = background_img_path.replace(/\\/g, " ")
                        if (background_img_path.slice(0, 1) !== "/") {
                            background_img_path = "/" + background_img_path;
                        }

                        style.backgroundImage = `url(${origin + background_img_path})`
                    }
                }
                return style
            } else return null
        },
        replace_origin_urls(attrs, element_data) {
            let origin = this.$store.state.origin;
            if (origin) {
                if (element_data.tag === "script" || element_data.tag === "img") {
                    if (attrs.src) {
                        if (
                            !attrs.src.includes("https://") &&
                            !attrs.src.includes("http://")
                        ) {
                            attrs.src = attrs.src.replace(/\\/g, "/");
                            if (attrs.src.slice(0, 1) !== "/") {
                                attrs.src = "/" + attrs.src;
                            }
                            attrs.src = origin + attrs.src;
                        }
                    }
                } else if (element_data.tag === "link" || element_data.tag === "a" && attrs.href) {
                    if (
                        !attrs.href.includes("https://") &&
                        !attrs.href.includes("http://") &&
                        attrs.href !== '#' &&
                        !attrs.href.includes('javascript:void')
                    ) {
                        attrs.href = attrs.href.replace(/\\/g, "/");
                        if (attrs.href.slice(0, 1) !== "/") {
                            attrs.href = "/" + attrs.href;
                        }
                        attrs.href = origin + attrs.href;
                    }
                }
            }
        },
        get_children(element_data, createElement) {
            let children = []
            let single_product_children = false
            let text_data = null

            if (element_data.child) {
                for (let item of element_data.child) {
                    if (item.node === "element") {
                        if (is_object(item.attr) && !is_empty(item.attr) && 'deepsel-single-blog' in item.attr) {
                            if (single_product_children) continue
                            single_product_children = true

                            if (this.is_blog_page()) {
                                for (let blog_data of this.blogs) {
                                    children.push(this.make_element_tree(createElement, item, {}, {blog_data}))
                                }
                            } else {
                                for (let blog_data of this.blogs) {
                                    children.push(this.make_element_tree(createElement, item, {}, {blog_data}))
                                }
                            }

                        } else {
                            children.push(this.make_element_tree(createElement, item));
                        }
                    } else if (item.node === "text") children.push(text_data ? text_data : item.text)
                }
            }

            if (this.transform && element_data.attr && 'deepsel-multi-blog' in element_data.attr) {
                children.push(createElement("style", [this.transform]))
            }

            return children
        },
    },
}