import BaseRenderMixin from "@/components/base/BaseRenderMixin";
import BaseComponent from "@/components/base/el/BaseComponent";
import {} from "@/components/base/utils";
import {get_esc_data, is_object, is_array, is_empty} from "@/components/base/utils";
const BaseSearchComponent = () => import("@/components/base/filter/BaseSearchComponent")
const BasePopupComponent = () => import("@/components/base/BasePopupComponent")
const BaseSingleBlogComponent = () => import("@/components/base/blog/BaseSingleBlogComponent")
import ComponentMap from "@/components/base/el/ComponentMap";

export default {
    name: "BaseCarouselComponent",
    mixins: [BaseComponent, BaseRenderMixin],
    props: {
        component_data: Object,
        products: Array,
        fetch_blog: Function,
        isProductListsCollection: [Boolean],
        data_set: String
        // on_carousel_mount: [Function]
    },
    data () {
        return {
            editing: false,
            isCarousel: true,
            optionsCarousel: '',
            intervalid: '',
            styleOverlay: {
                position: "fixed",
                top: "0",
                left: "0",
                width: "100vw",
                overfolow: "hidden",
                height: "100vh",
                zIndex: "9",
                opacity: '0.99',
                transtion: '.3s',
            },
            blogs: ''
        }
    },
    async mounted() {
        if (this.data_set === 'blog-list') {
            this.fetch_blog().then((res) => {
                this.blogs = res.data.posts
                this.get_option()
            })
        } else {
            if (this.component_data.attr && this.component_data.attr['options']) {
                this.optionsCarousel = JSON.parse(this.component_data.attr['options'].replaceAll("'", '"'))
                this.intervalid = setInterval(() => {
                    if (window.$ && window.$(this.$el).children(".owl-carousel").data('owl.carousel')) {
                        clearInterval(this.intervalid)
                        let options = window.$(this.$el).children(".owl-carousel").data('owl.carousel').options
                        for(let key in options) {
                            if(this.optionsCarousel[key] !== undefined) {
                                options[key] = this.optionsCarousel[key]
                            }
                        } 
                        window.$(this.$el).children(".owl-carousel").trigger('refresh.owl.carousel');
                    }
                }, 200)
            } else if (this.$store.state.edit_mode) {
                this.intervalid = setInterval(() => {
                    if (window.$ && window.$(this.$el).children(".owl-carousel").data('owl.carousel')) {
                        let options = Object.assign({}, window.$(this.$el).children(".owl-carousel").data('owl.carousel').options)
                        delete options.responsiveBaseElement
                        this.component_data.attr['options'] = JSON.stringify(options)
                        this.optionsCarousel = Object.assign({}, options)
                        clearInterval(this.intervalid)
                    }
                }, 1000)
            }
        }
    },
    watch: {
        products(val, Oval) {
            if (!is_empty(Oval)) {
                window.$(this.$el).children(".owl-carousel").trigger('destroy.owl.carousel')
                setTimeout(() => {
                    window.$(this.$el).children(".owl-carousel").owlCarousel(this.optionsCarousel)
                }, 500);
            }
        },
        // blogs(val, Oval) {
        //     console.log("blogs list change", val);
        //     if (!is_empty(Oval)) {
        //         window.$(this.$el).children(".owl-carousel").trigger('destroy.owl.carousel')
        //         setTimeout(() => {
        //             window.$(this.$el).children(".owl-carousel").owlCarousel(this.optionsCarousel)
        //         }, 500);
        //     }
        // }
    },
    methods: {
        get_option() {
            if (this.component_data.attr && 'is-owl-carousel' in this.component_data.attr && this.component_data.attr['options']) {
                this.optionsCarousel = JSON.parse(this.component_data.attr['options'].replaceAll("'", '"'))
                this.intervalid = setInterval(() => {
                    if (window.$) {
                        if (window.$(this.$el).children(".owl-carousel").data('owl.carousel')) {
                            console.log('finded');
                            this.refeshCarousel()
                            clearInterval(this.intervalid)
                        } else if (window.$(this.$el).children(".owl-carousel").owlCarousel) {
                            console.log('finded');
                            this.refeshCarousel()
                            clearInterval(this.intervalid)
                        }
                    }
                }, 300)
            } else {
                this.intervalid = setInterval(() => {
                    if (window.$ && window.$(this.$el).children(".owl-carousel").data('owl.carousel')) {
                        console.log('get_option');
                        let options = Object.assign({}, window.$(this.$el).children(".owl-carousel").data('owl.carousel').options)
                        delete options.responsiveBaseElement
                        this.optionsCarousel = Object.assign({}, options)
                        clearInterval(this.intervalid)
                    }
                }, 500)
            }
        },
        make_element_tree(createElement, element_data, options = {}, context = null) {
            if (this.data_set === 'blog-list' && is_empty(this.blogs)) return createElement('div',"Không tìm thấy dữ liệu")
            // if (this.data_set === 'product-list' && is_empty(this.products)) return ["Không tìm thấy dữ liệu"]
            
            let data = {}
            let el, props, attrs, style, children
            [el, props] = this.get_el_props(element_data, context)
            attrs = this.get_attrs(element_data, context)
            children = this.get_children(element_data, createElement)
            style = this.get_style(element_data, context)

            if(element_data.attr && 'is-owl-carousel' in element_data.attr) {
                if (this.editing) {
                    children.unshift(createElement('i', {
                        class: 'fas fa-arrow-right position-absolute',
                        style: {right: '-2px',top: "-40px",color: "#e94057",zIndex: "10",border: "#e94057 dashed",borderWidth: "3px 3px 0 0",fontSize: "1rem",padding: "10px"},
                        on: {click: () => window.$(this.$el).children(".owl-carousel").trigger('next.owl.carousel')}
                    }))
                    children.unshift(
                        createElement('i',{
                            class: 'fas fa-arrow-left position-absolute',
                            style: {right: "40px",top: "-40px",color: "#e94057",zIndex: "10",border: "#e94057 dashed",borderWidth: "3px 3px 0 3px",fontSize: "1rem",padding: "10px"},
                            on: {click: () => window.$(this.$el).children(".owl-carousel").trigger('prev.owl.carousel')}
                        }))

                    children.push(createElement('div', { style: this.styleOverlay, on: { click: this.closeEditingCarousel } }))
                    children.push(createElement('style', {}, [`[data-id='${this.data_id}'] .owl-carousel { z-index: 10 !important;}`]))
                }
                let class_array = [{"deepsel-focus": this.is_editing}]
                if (this.component_data.attr && this.component_data.attr.class) {
                    let class_value

                    if (Array.isArray(this.component_data.attr.class)) {
                        class_value = this.component_data.attr.class.join(" ")
                    } else class_value = this.component_data.attr.class

                    class_array.push(class_value)
                }
                data = {
                    class: class_array,
                    attrs: {
                        draggable: false,
                    },
                    style: {...this.styling,...(this.editing ? {
                        position: 'relative',
                        outlineStyle: 'dashed !important',
                        outlineWidth: '3px !important',
                        outlineOffset: '-3p !important',
                        outlineColor: '#e94057 !important'
                    } : {})},
                    on: {
                        mouseenter: this.mouse_enter,
                        mousedown: this.mouse_down,
                    },
                }
            }

            if (props) data.props = props
            if (attrs) data.attrs = {...attrs, ...data.attrs}
            if (style) data.style = {...style, ...data.style}

            for (let key in options) data[key] = options[key]

            return createElement(
                el,
                data,
                children
            )
        },
        is_special_div(element_data) {
            if (element_data.tag !== 'div') return false

            if (element_data.child && is_array(element_data.child)) {
                let text_nodes = element_data.child.filter(c => c.node === 'text')
                if (text_nodes.length > 0) return true
            }

            if (element_data.attr &&
                element_data.attr.style &&
                element_data.attr.style.backgroundImage) return true

            return false
        },
        is_special_section(element_data) {
            if (element_data.tag !== 'section') return false

            if (element_data.child && is_array(element_data.child)) {
                let text_nodes = element_data.child.filter(c => c.node === 'text')
                if (text_nodes.length > 0) return true
            }

            if (element_data.attr &&
                element_data.attr.style &&
                element_data.attr.style.backgroundImage) return true

            return false
        },
        get_el_props(element_data, context) {
            let el = element_data.tag
            let props = null
            if (element_data.attr) {
                if ('deepsel-single-blog' in element_data.attr) {
                    el = BaseSingleBlogComponent
                    props = {
                        component_data: element_data,
                        blog_data: context.blog_data
                    }
                    return [el, props]
                }
                else if ('deepsel-search' in element_data.attr ||
                    (element_data.tag === 'input' && element_data.attr.type === 'search')) {
                    el = BaseSearchComponent
                    props = {component_data: element_data}
                    return [el, props]
                }
                else if ((element_data.attr && element_data.attr.class && element_data.attr.class.includes('modal')) ||
                    'deepsel-popup' in element_data.attr) {
                    el = BasePopupComponent
                    props = {component_data: element_data}
                    return [el, props]
                } else if (element_data.attr && element_data.attr.class && element_data.attr.class.includes('owl-carousel')) {
                    return [element_data.tag]
                }
            }

            if (this.editing) {
                if (Object.keys(ComponentMap).includes(element_data.tag)) {
                    el = ComponentMap[element_data.tag]
                    props = {component_data: element_data}
                }
            }

            return [el, props]
        },
        get_children(element_data, createElement) {
            let children = []
            let esc_data = get_esc_data(element_data, this.$store.state.common_data)
            let single_product_children = false
            let single_blog_children = false

            if (element_data.child) {
                for (let item of element_data.child) {
                    if (item.node === "element") {

                        if (item.tag === "link" && item.attr && item.attr.href) {
                            if (item.attr.href.includes("bootstrap")) continue;
                        }

                        if (item.attr && is_object(item.attr) && !is_empty(item.attr) && 'deepsel-single-product' in item.attr) {
                            if (single_product_children) continue
                            single_product_children = true

                            for (let product_data of this.products) {
                                children.push(this.make_element_tree(createElement, item, {}, {product_data}))
                            }
                        } else if (item.attr && 'deepsel-single-blog' in item.attr) {
                            if (single_blog_children) continue
                            single_blog_children = true
                            for (let blog_data of this.blogs) {
                                children.push(this.make_element_tree(createElement, item, {}, {blog_data}))
                            }

                        } else {
                            children.push(this.make_element_tree(createElement, item));
                        }


                    } else if (item.node === "text") {
                        let esc_data = get_esc_data(element_data, this.$store.state.common_data)
                        if (esc_data) {
                            children.push(esc_data)
                        } else children.push(item.text);
                    }
                }
                if (!element_data.child.find(e => e.node === "text") && esc_data) {
                    children = [esc_data]
                }
            } else if (esc_data) {
                children.push(esc_data);
            }
            return children
        },
        replace_origin_urls(attrs, element_data) {
            let origin = this.$store.state.origin;
            if (origin) {
                if (element_data.tag === "script" || element_data.tag === "img") {
                    if (attrs.src) {
                        if (
                            !attrs.src.includes("https://") &&
                            !attrs.src.includes("http://")
                        ) {
                            attrs.src = attrs.src.replace(/\\/g, "/");
                            if (attrs.src.slice(0, 1) !== "/") {
                                attrs.src = "/" + attrs.src;
                            }
                            attrs.src = origin + attrs.src;
                        }
                    }
                    if (attrs['data-src']) {
                        if (!attrs['data-src'].includes("https://") && !attrs['data-src'].includes("http://")) {
                            attrs['data-src'] = attrs['data-src'].replace(/\\/g, "/");
                            if (attrs['data-src'].slice(0, 1) !== "/") {
                                attrs['data-src'] = "/" + attrs['data-src'];
                            }
                            attrs['data-src'] = origin + attrs['data-src'];
                        }
                    }
                } else if (element_data.tag === "link") {
                    if (
                        !attrs.href.includes("https://") &&
                        !attrs.href.includes("http://") &&
                        attrs.href !== '#' &&
                        !attrs.href.includes('javascript:void')
                    ) {
                        attrs.href = attrs.href.replace(/\\/g, "/");
                        if (attrs.href.slice(0, 1) !== "/") {
                            attrs.href = "/" + attrs.href;
                        }
                        attrs.href = origin + attrs.href;
                    }
                }
            }
        },
        saveSetting() {
            let options = window.$(this.$el).children(".owl-carousel").data('owl.carousel').options
            for(let key in options) {
                if(this.optionsCarousel[key] !== undefined) {
                    options[key] = this.optionsCarousel[key]
                }
            }
            window.$(this.$el).children(".owl-carousel").trigger('refresh.owl.carousel');
            this.component_data.attr['options'] = JSON.stringify(this.optionsCarousel)
        },
        editingCarousel() {
            let options = window.$(this.$el).children(".owl-carousel").data('owl.carousel').options
            for(let key in options) {
                if (this.$store.state.edit_mode && (key === 'autoplay' || key === 'loop' || key === 'mouseDrag' || key === 'touchDrag' || key === 'pullDrag')) {
                    options[key] = false
                } 
            }
            window.$(this.$el).children(".owl-carousel").trigger('destroy.owl.carousel');
            this.editing = true

            setTimeout(() => {
                window.$(this.$el).children(".owl-carousel").owlCarousel(options)
            }, 500);
            // window.$(this.$el).children(".owl-carousel").trigger('refresh.owl.carousel');
        },
        closeEditingCarousel() {
            window.$(this.$el).children(".owl-carousel").trigger('destroy.owl.carousel');
            this.editing = false

            setTimeout(() => {
                window.$(this.$el).children(".owl-carousel").owlCarousel(this.optionsCarousel)
            }, 500);
        },
        plusItem() {
            if (this.component_data.attr && 'is-owl-carousel' in this.component_data.attr) {
                this.component_data.child[0].child.push(JSON.parse(JSON.stringify(this.component_data.child[0].child[0])))
                this.refeshCarousel()
            }
        },
        minusItem() {
            if (this.component_data.attr && 'is-owl-carousel' in this.component_data.attr) {
                if (this.component_data.child[0].child.length < 2) return
                this.component_data.child[0].child.pop()
                this.refeshCarousel()
            }
        },
        unshiftItem() {
            if (this.component_data.attr && 'is-owl-carousel' in this.component_data.attr) {
                this.component_data.child[0].child.unshift(JSON.parse(JSON.stringify(this.component_data.child[0].child[0])))
                this.refeshCarousel()
            }
        },
        refeshCarousel() {
            window.$(this.$el).children(".owl-carousel").trigger('destroy.owl.carousel')
            setTimeout(() => {
                window.$(this.$el).children(".owl-carousel").owlCarousel(this.optionsCarousel)
            }, 500)
        }
    },
};
